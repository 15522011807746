<template>
  <default-layout>
    <div>
      <img src="@/assets/images/lenderwarning.png" class="inline" alt />
    </div>
  </default-layout>
</template>
<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
export default {
  components: { DefaultLayout },
  name: 'LenderNoti',
};
</script>
